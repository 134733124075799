import { Dispatch, useCallback, useMemo } from 'react';
import { DateValue } from 'react-aria';
import Button from 'src/components/Button';
import DatePicker from 'src/components/date-picker/DatePicker';
import ExtraLabel from 'src/components/ExtraLabel';
import SelectField from 'src/components/SelectField';
import TextField from 'src/components/TextField';
import { useSessionCancellationReasonQuery } from 'src/dictionaries/dictionariesApi';
import TimeRangePicker from 'src/form-partials/TimeRangePicker';
import { IconPlus } from 'src/icons';
import { isFutureDate } from 'src/utils/isFutureDate';

import { ScheduleSessionAvailableHoursResponse } from '../schedule.types';
import GoalsForm from './GoalsForm';
import { FormActions, FormData, FormDataErrors } from './RecordSessionContext';

type SessionFormPartProps = {
  dispatch: Dispatch<FormActions>;
  disabled?: boolean;
  locationsOptions: { label: string; value: string }[];
  errors?: FormDataErrors;
  isDateDisabled?: (d: DateValue) => boolean;
  availability?: ScheduleSessionAvailableHoursResponse;
  isRelatedService?: boolean;
  isPara?: boolean;
  groupSize?: string;
} & Pick<
  FormData,
  | 'studentId'
  | 'date'
  | 'startTime'
  | 'finishTime'
  | 'location'
  | 'note'
  | 'goals'
  | 'numberOfStudents'
  | 'cancellationReason'
  | 'cancellationReasonId'
>;

const SessionFormPart = ({
  dispatch,
  disabled,
  studentId,
  errors,
  isDateDisabled,
  date,
  availability,
  startTime,
  finishTime,
  location,
  locationsOptions,
  note,
  numberOfStudents,
  cancellationReasonId,
  cancellationReason,
  goals,
  isRelatedService,
  groupSize,
}: SessionFormPartProps) => {
  const selectedDateIsInFuture = useMemo(() => isFutureDate(date), [date]);

  const { data: cancellationReasonOptions = [] } = useSessionCancellationReasonQuery(undefined, {
    skip: !cancellationReasonId,
  });

  const handleDateChange = useCallback(
    (date) => {
      dispatch({ type: 'setDate', payload: date });
      if (isFutureDate(date)) {
        dispatch({ type: 'clearGoals' });
      }
    },
    [dispatch],
  );

  const handleOnSelectTime = useCallback(
    (startTime, finishTime) => {
      dispatch({ type: 'setStartTime', payload: startTime });
      dispatch({ type: 'setFinishTime', payload: finishTime });
    },
    [dispatch],
  );

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <ExtraLabel>Location</ExtraLabel>
        <SelectField
          options={
            locationsOptions?.length
              ? locationsOptions
              : location
              ? [{ label: location, value: location }]
              : undefined
          }
          disabled={locationsOptions.length === 0 || disabled}
          defaultValue={locationsOptions.length > 0 ? locationsOptions[0].value : location}
          value={location}
          size="md"
          className="w-full"
          onValueChange={(selectedValue) => {
            dispatch({ type: 'setLocation', payload: selectedValue });
          }}
          error={errors?.location}
        />
      </div>
      <div className="flex flex-col gap-3">
        <ExtraLabel>Schedule</ExtraLabel>
        <div className="grid grid-cols-2 gap-1 sm:gap-4">
          <DatePicker
            label="Date"
            value={date}
            onChange={handleDateChange}
            error={errors?.date}
            hasError={!!errors?.date}
            disabled={disabled}
            isDateDisabled={isDateDisabled}
          />
          <TimeRangePicker
            label
            size="sm"
            onSelectTime={handleOnSelectTime}
            errors={errors}
            startTime={startTime}
            availability={availability}
            expectAvailability
            finishTime={finishTime}
            disabled={disabled}
            isRelatedService={!!isRelatedService}
          />
        </div>
      </div>
      {!!groupSize && (
        <div className="flex flex-col gap-4">
          <ExtraLabel>Group size</ExtraLabel>
          <SelectField
            options={Array.from({ length: 8 }, (_, i) => ({
              label: (i + 1).toString(),
              value: (i + 1).toString(),
            }))}
            placeholder="1"
            size="md"
            className="w-full"
            defaultValue="1"
            value={String(numberOfStudents)}
            onValueChange={(selectedValue) => {
              dispatch({ type: 'numberOfStudents', payload: Number(selectedValue) });
            }}
            disabled={disabled}
          />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <ExtraLabel>Notes</ExtraLabel>
        {note !== undefined ? (
          <>
            <TextField
              size="md"
              className="grow"
              placeholder="Add a note"
              onChange={(e) => {
                dispatch({ type: 'setNote', payload: e.target.value });
              }}
              value={note}
              disabled={disabled}
            />
            <div className="flex gap-4">
              <Button
                preset="warning"
                onClick={() => {
                  dispatch({ type: 'removeNote' });
                }}
                disabled={disabled}
              >
                Cancel Note
              </Button>
              <Button
                preset="warning"
                onClick={() => {
                  dispatch({ type: 'setNote', payload: '' });
                }}
                disabled={disabled || (!disabled && !note.length)}
              >
                Clear
              </Button>
            </div>
          </>
        ) : (
          <Button
            preset="quaternary"
            onClick={() => {
              dispatch({ type: 'setNote', payload: '' });
            }}
            Icon={IconPlus}
            disabled={disabled}
          >
            Add Note
          </Button>
        )}
      </div>
      {!cancellationReasonId && (
        <GoalsForm
          selectedStudentId={studentId}
          dispatch={dispatch}
          goals={goals}
          error={errors?.goals}
          disabled={disabled || selectedDateIsInFuture}
        />
      )}
      {cancellationReasonId && (
        <div>
          <ExtraLabel className="mb-4">Cancelation details</ExtraLabel>
          <TextField
            value={
              cancellationReason ||
              cancellationReasonOptions.find((o) => o.key === cancellationReasonId)?.value
            }
            disabled
            size="md"
          />
        </div>
      )}
    </div>
  );
};

export default SessionFormPart;
